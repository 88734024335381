import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const PageSC = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;

  a.forget {
    color: white;
  }

  > div {
    flex: 1;
    height: 100%;

    &.login-box {
      background: #4f75f8;
      width: 100%;

      text-align: center;

      .logo {
        height: 64px;
        margin-top: ${props => (props.theme.mobile ? '1.5rem' : '3rem')};
        margin-bottom: ${props => (props.theme.mobile ? '1.5rem' : '9rem')};

        img {
          display: inline-block;
          height: 64px;
        }
      }

      h2 {
        color: #fff;
        font-size: ${props => (props.theme.mobile ? '15px' : 'inherit')};
      }

      form {
        max-width: 420px;
        margin-left: auto;
        margin-right: auto;
        padding-top: ${props => (props.theme.mobile ? '1.5rem' : '0.5rem')};

        p {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: center;

          input {
            //border: 1px solid #d1d7e0;
            border: none;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
            color: #34313f;
            //height: 3rem;
            padding: 0.75rem 1rem;
            outline: none;
            flex: 1;
          }

          .icon {
            background: #fff;
            //height: 3rem;
            padding: 0.75rem 1rem;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
          }
        }

        button {
          width: 80%;
          color: #fff;
          border: none;
          background-color: #41539b;
          border-radius: 1.5rem;
          margin-top: 0.25rem;
          letter-spacing: 1px;
          line-height: 1.5rem;
          height: 3rem;
          padding: 0.75rem 1rem;
          text-transform: uppercase;
          transition: background-color 0.2s ease;

          &:hover {
            background-color: #3a426e;
          }
        }
      }
    }
  }
`;
