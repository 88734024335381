import { UserData } from '@innedit/innedit';
import { Form, useDispatch, useSelector } from 'dataformjs';
import { Link, navigate } from 'gatsby';
import moment from 'moment';
import React, { FC, useEffect } from 'react';

import logo from '../../../../images/innedit-white.svg';
import { load } from '../User/actions';
import datas from './datas.json';
import { PageSC } from './style';

const Login: FC = () => {
  const dispatch = useDispatch();
  const { isLogged, loadingUser } = useSelector(
    (globalState: any) => globalState.cms,
  );
  useEffect(() => {
    if (isLogged && !loadingUser) {
      navigate(`/dashboard`);
    }
  }, [isLogged, loadingUser]);

  const handleFormSubmit = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<void> => {
    const result = await UserData.login(email, password);
    if (result.user) {
      dispatch(load(result.user));
    }
    console.info('result', result);
  };

  return (
    <PageSC>
      <div className="login-box">
        <div className="logo">
          <a href="https://www.innedit.fr">
            <img alt="Innedit" id="logo" src={logo} />
          </a>
        </div>
        <h2>Connectez-vous à votre contenu</h2>
        <Form
          datas={datas}
          name="login"
          onSubmit={handleFormSubmit}
          submitProps={{
            label: 'login',
          }}
        >
          <Link className="forget" to="/forgetpassword">
            Mot de passe oublié ?
          </Link>
        </Form>
        <div className="mt-6">{moment().toISOString()}</div>
      </div>
    </PageSC>
  );
};

export default Login;
