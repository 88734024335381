import { Link, PageProps } from 'gatsby';
import React, { FC } from 'react';

import Login from '../core/sysext/CMS/containers/Login';

const IndexPage: FC<PageProps> = () => (
  <div>
    <h1>Innédit CMS</h1>
    <Link to="/dashboard/">Dashboard</Link>
    <Login />
  </div>
);

export default IndexPage;
